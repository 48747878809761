

























import Vue from 'vue';
import Hero from '@/components/Hero.vue';
import mixins from 'vue-typed-mixins';
import { ViewMixin } from '@/mixins/ViewMixin';

export default mixins(ViewMixin).extend({
  name: 'PageNotFound',

  components: {
    Hero,
  },

  metaInfo: {
    title: '404',
  },
});
